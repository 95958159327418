<template>
  <div class="row justify-center bg-grey-3">
    <q-dialog v-model="confirm" persistent>
      <q-card style="min-width:250px">
        <q-card-section class="q-gutter-md">
          <q-input
            outlined
            readonly
            label="Musyrif/ah"
            :value="user.nama"
          ></q-input>
          <q-select
            outlined
            v-if="user.is_super_user == '1'"
            v-model="selMusyrif"
            label="Musyrif"
            :options="musyrif"
          />
          <q-select
            outlined
            v-model="selKegiatan"
            label="Kegiatan"
            :options="kegiatan"
          />
          <q-input outlined label="Tanggal" v-model="date" readonly>
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    :options="dateOptionFn"
                    v-model="date"
                    mask="YYYY-MM-DD"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>

        <q-card-actions align="right" class="q-pt-lg">
          <q-btn @click="$router.go(-1)" flat label="Kembali" color="primary" />
          <q-btn
            filled
            label="Mulai"
            color="primary"
            v-close-popup
            @click="cekKehadiran()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-sm">
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        wrap-cells
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 214px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>Nama</th>
            <th style="max-width:100px">Status</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ val.nama }}</td>
            <td style="min-width:100px">
              <q-select
                dense
                outlined
                v-model="val.status"
                :options="opsiStatus"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          color="negative"
          class="full-width no-border-radius"
          @click="tambahKehadiran()"
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
    <q-dialog v-model="guard" persistent>
      <q-card style="width:250px">
        <q-card-section class="row items-center">
          <span class="q-ml-sm"
            >Apa anda yakin ingin menyimpan daftar kehadiran?</span
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="primary" v-close-popup />
          <q-btn
            unelevated
            label="Ya"
            color="primary"
            @click="tambahKehadiran()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      date: null,
      isTimeManual: false,
      pj: "",
      user: {},

      musyrif: [],
      selMusyrif: {
        value: null,
      },

      guard: false,
      confirm: true,
      opsiStatus: ["Hadir", "Masbuq", "Haid", "Tidak Hadir"],
      selKegiatan: { label: "SHUBUH", value: 1 },
      kegiatan: [
        { label: "SHUBUH", value: 1 },
        { label: "DZUHUR", value: 2 },
        { label: "ASHAR", value: 3 },
        { label: "MAGHRIB", value: 4 },
        { label: "ISYA", value: 5 },
        { label: "TAHAJUD", value: 6 },
        { label: "TILAWAH", value: 7 },
        { label: "ALMASURAT", value: 8 },
        { label: "TASQIF", value: 9 },
        { label: "TAHFIDZ", value: 10 },
        { label: "KARANTINA TAHDIFZ", value: 11 },
        { label: "PUASA KAMIS", value: 12 },
        { label: "SHALAT RAWATIB", value: 13 },
      ],
      siswa: ["Siswa 1", "Siswa 2", "Siswa 3"],
      searchTerm: "",
    };
  },
  async mounted() {
    await this.getUser();
    this.date = moment().format("YYYY-MM-DD");
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async cekKehadiran() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/penilaian/absensi/cekabsen/${this.date}/${this.selKegiatan.label}/${this.selMusyrif.value}`
        )
        .then((resp) => {
          if (resp.data.length > 0) {
            this.$q.notify({
              message:
                "Data Absen untuk kegiatan " +
                this.selKegiatan.label +
                " pada tanggal yg dipilih sudah Ada",
              color: "negative",
            });
            this.date = moment().format("YYYY-MM-DD");
            this.confirm = true;
          } else {
            this.getListSantri();
            this.confirm = false;
          }
        });
      this.$q.loading.hide();
    },
    dateOptionFn(date) {
      return date <= moment().format("YYYY/MM/DD");
    },
    async getListSantri() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/penilaian/absensi/getsantri/" + this.selMusyrif.value) //id_musyrif masih dummy, harusnya mengikuti id musrif yg login
        .then((resp) => {
          resp.data.forEach((element, i) => {
            element.status = "Hadir";
            element.tanggal = this.date;
            element.pj = this.pj;
            element.tipe = this.selKegiatan.label;
            element.is_hadir = true;
            element.is_haid = false;
            element.is_masbuq = false;
          });
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    tambahKehadiran() {
      if (!this.guard) {
        this.guard = true;
      } else {
        this.siswa.forEach((element) => {
          if (element.status == "Hadir") {
            element.is_hadir = true;
            element.is_haid = false;
            element.is_masbuq = false;
          } else if (element.status == "Haid") {
            element.is_hadir = true;
            element.is_haid = true;
            element.is_masbuq = false;
          } else if (element.status == "Masbuq") {
            element.is_hadir = true;
            element.is_haid = false;
            element.is_masbuq = true;
          } else {
            element.is_hadir = false;
            element.is_haid = false;
            element.is_masbuq = false;
          }
        });

        let nama = "";
        if (this.user.is_super_user == "1") {
          nama = this.selMusyrif.label;
        } else {
          nama = this.user.nama;
        }
        this.$http
          .post(
            `/penilaian/absensi/tambah/${localStorage.getItem(
              "id_tahun_ajaran"
            )}/${this.selMusyrif.value}/${nama}`,
            this.siswa
          )
          .then((resp) => {
            this.$q.notify({
              message: "Absensi Berhasil Disimpan!",
              color: "positive",
            });
            this.$router.push("/");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
